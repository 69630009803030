import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';

type AuthContextValue = {
  isLeaderAuthenticated: boolean;
  avatar?: string;
  leaderId?: string;
};

const initialValue: AuthContextValue = {
  isLeaderAuthenticated: false,
  avatar: '',
};

type ProviderProps = {
  value: AuthContextValue;
  children: ReactNode;
};

const context = createContext<AuthContextValue>(initialValue);

export function useAuth() {
  return useContext(context);
}

export const AuthProvider = ({ value, children }: ProviderProps) => (
  <context.Provider value={value}>{children}</context.Provider>
);
